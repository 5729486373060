import React, { useEffect, useState } from "react";
import { PhoneOutlined, SearchOutlined } from "@ant-design/icons";
import { FloatButton, Input, Modal, notification, Select, Table } from "antd";
import "./CallDetails.css";
import type { TableColumnsType, TableProps } from "antd";
import { useGetEnquiryListWithIdName } from "../../Utilities/tanStackQueries";
import RingModel from "./RingModel";
import CallFollwDateModel from "./CallFollwDateModel";
import InComingModel from "./InComingModel";
import axios from "axios";
import HTTPSCalls from "../../Services/HTTPCalls";

interface DataType {
  key: React.Key;
  id?: string;
  name: string;
  phone: number;
  call: string;
}

const CallDetails: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [modelOpen, setModelOpen] = React.useState<boolean>(false);
  const [callId, setCallId] = React.useState<string | undefined>("");
  const [ringModelOpen, setRingModelOpen] = React.useState<boolean>(false);
  const [isCallEnded, setIsCallEnded] = React.useState<boolean>(false);
  const [inComingModel, setInComingModel] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);

  const { data: EnquiryListWithIdName, isLoading: isLoading } =
    useGetEnquiryListWithIdName(modelOpen, search);
  // console.log("EnquiryListWithIdName", EnquiryListWithIdName);

  const handleClickToCall=async(record:any)=>{
    const res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.TATA_TELE_CLICK_CALL+record?.phone.toString(),{},{});
    console.log("res",res);
    
    if (res?.data?.success) {
      notification.success({
        placement: "bottomRight",
        message: "",
        description: res?.data?.message || "Call initiated",
      });
    } else{
      notification.error({
        placement: "bottomRight",
        message: "",
        description: res?.data?.message || "Something went wrong",
      });
    }
    setCallId("");
    setData(record);
    setModelOpen(false);
    // setRingModelOpen(true);
  }
  const columns: TableColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record, index: number) => (
        <div
          onClick={() => {
            //console.log(record?.id);
            setCallId(record?.id);
          }}
          style={{ cursor: "pointer" }}
        >
          <span style={{ fontWeight: "bold" }}>{record?.name}</span>
          <br />
          <span style={{ color: "gray" }}>{record?.phone}</span>
        </div>
      ),
      width: "35%",
    },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   width: "15%",
    // },
    {
      title: "",
      dataIndex: "call",
      width: "6%",
      render: (_, record) => (
        <div style={{ fontWeight: 800, color: "rgb(16, 124, 16)" }}>
          {record.id === callId && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleClickToCall(record);
              }}
            >
              <PhoneOutlined
                rotate={98}
                style={{
                  fontSize: 20,
                }}
              />
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    //console.log("callId", callId);
  }, [callId, inComingModel]);

  return (
    <>
      <FloatButton
        shape="square"
        type="primary"
        style={{ insetInlineEnd: 24 }}
        icon={<PhoneOutlined rotate={100} />}
        onClick={() => setModelOpen(true)}
      />
      {modelOpen && (
        <Modal
          className="height"
          mask={false}
          closable={false}
          open={modelOpen}
          width={300}
          onCancel={() => {
            setCallId("");
            setSearch("");
            setModelOpen(false);
          }}
          footer={null}
          style={{ top: "45%", left: "41%" }}
        >
          <Input
            placeholder="Type a Name or Number Here..."
            suffix={<SearchOutlined />}
            allowClear
            onChange={(e) => {
              setSearch(e.target.value);
              setCallId("");
            }}
          />

          <Table
            rowClassName={(record, index) => {
              return record.id === callId ? "ca-active-cell" : "";
            }}
            columns={columns}
            dataSource={EnquiryListWithIdName?.result?.items}
            pagination={false}
            loading={isLoading}
            style={{ paddingTop: 5 }}
            scroll={{ y: 314 }}
          />
        </Modal>
      )}

      {ringModelOpen && (
        <RingModel
          ringModelOpen={ringModelOpen}
          setRingModelOpen={setRingModelOpen}
          data={data}
          setIsCallEnded={setIsCallEnded}
        />
      )}

      {isCallEnded && (
        <CallFollwDateModel
          isOpen={isCallEnded}
          setIsCallEnded={setIsCallEnded}
          data={data}
        />
      )}

      {
        inComingModel && (
          <InComingModel
            isOpenIncoming={inComingModel}
            setInComingModel={setInComingModel}
            data={data}
            setIsCallEnded={setIsCallEnded}
            setRingModelOpen={setRingModelOpen}
            
          />
        )
      }
    </>
  );
};

export default CallDetails;
